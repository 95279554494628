import { useQuery } from '@apollo/client';
import { Box, Button, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import { ErrorNotifier } from '../../common/error/ErrorNotifier';
import { Popover } from '../../common/popover/Popover';
import { columnTypes, Table } from "../../common/table/Table";
import { Item } from '../../styles/common';
import { EditUser } from './EditUser';
import { columns, sortByOptions } from './user';
import { USERS } from './users.queries';

const perPage = Number(process.env.REACT_APP_PAGINATION_ROWS_PER_PAGE ?? 5);

export const Users: React.FC<any> = (): JSX.Element => {
    let variables = React.useRef<any>({
        "pagination": {
            "skip": 0,
            "take": perPage
        }
    });
    const { data, loading, error, refetch } = useQuery(USERS, { variables });

    const [search, setSearch] = React.useState<string>('');
    const [sortBy, setSortBy] = React.useState<string>();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

    const [userToEdit, setUserToEdit] = useState<any>(null);
    const [isEditUserOpen, setIsEditUserOpen] = useState(false);

    React.useEffect(() => {
        variables.current = {
            pagination: {
                take: rowsPerPage,
                skip: page * rowsPerPage,
            }
        };

        if (sortBy) {
            const [key, sortOrder] = sortBy.split(' ');
            variables.current = {
                ...variables.current,
                orderBy: {[key]: sortOrder},
            }
        }

        refetch(variables.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, sortBy]);

    const onSearch = () => {
        variables.current = {
            ...variables.current,
            where: {
                "OR": [
                    { "email": { "contains": search } },
                    { "username": { "contains": search } },
                    { "lastName": { "contains": search } },
                    { "firstName": { "contains": search } },
                ]
            },
        };

        refetch(variables.current);
    }

    const editFn = useCallback((item: any) => {
        setUserToEdit(item);
        setIsEditUserOpen(true);
    }, []);

    if (loading) return <h4>Loading...</h4>;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Popover
                open={isEditUserOpen}
                handleClose={() => {
                  setIsEditUserOpen(false);
                }}
            >
                <EditUser
                    user={userToEdit}
                    handleClose={(isSuccess: number | undefined) => {
                        if (isSuccess) {
                          refetch(variables.current);
                        }
                        setIsEditUserOpen(false);
                      }}
                />
            </Popover>
            <ErrorNotifier error={error} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography sx={{ m: 2 }} variant="h4">Users</Typography>

                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-helper-label">Sort By</InputLabel>
                                <Select
                                    value={sortBy}
                                    label="Sort By"
                                    onChange={((e: any) => setSortBy(e.target.value))}
                                >
                                    {sortByOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container direction="row" alignItems="center">
                            <FormControl variant="standard" sx={{ m: 1, mt: 3 }}>
                                <Input
                                    value={search}
                                    onChange={((e: any) => setSearch(e.target.value))}
                                    id="search"
                                    startAdornment={
                                        <InputAdornment sx={{ height: '25px' }} position="start">
                                            <AiOutlineSearch size={20} />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="user-search-helper-text">Search user by First name, Last name, email or username</FormHelperText>
                            </FormControl>
                            <Button sx={{ height: '40px' }} variant="contained" color="success" onClick={onSearch}>Search</Button>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <Table
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            loading={loading}
                            columns={[
                                ...columns,
                                {
                                  label: "",
                                  key: "action",
                                  textAlign: "right",
                                  type: columnTypes.EDIT,
                                  actionFn: editFn,
                                },
                              ]}
                            rows={data.fetchUsers.users}
                            total={data.fetchUsers.total}
                        />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}