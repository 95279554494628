import { useLazyQuery } from "@apollo/client";
import { Button, Stack, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getAuth } from "firebase/auth";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useToast } from "../../common/hooks/useToast";
import { saveTokens } from "../../common/manage_tokens";
import { FETCH_USER_ROLE } from "../../graphql/queries";
import { PhoneSignInError, PhoneSignInStatus, useSignInWithPhoneNumber } from "./useSignInWithPhoneNumber";

interface PropsInterface {
  loginMethod: string;
}

export const LoginDetails: React.FC<PropsInterface> = ({
  loginMethod,
}: PropsInterface) => {
  return loginMethod === "email" ? (<LoginWithEmail />) : (<LoginWithPhoneNumber />);
};

const LoginWithEmail = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { error: toastError, success } = useToast();

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);



  const [getUserRole, { data: userRole, error: userRoleError, },] = useLazyQuery(FETCH_USER_ROLE);

  const [values, setValues] = useState({
    id: "",
    password: "",
  });

  useEffect(() => {
    if (error) {
      toastError("Login failed. Please try again");
    } else if (user) {
      console.log(user);
      user.user.getIdToken().then((token) => {
        saveTokens(token);
        getUserRole();

      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, user, success, toastError]);

  useEffect(() => {
    if (userRoleError) {
      toastError("Login failed. Please try again");
      return;
    }
    console.log('userRole', userRole);
    if (userRole && userRole.userRole === 'ADMIN') {
      success("Login Successful!");
        navigate("/users");
        return;
    } else { }
  }, [toastError, userRoleError, navigate, success, userRole]);

  const handleChange = useCallback((event: any) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    try {
      signInWithEmailAndPassword(values.id, values.password);
    } catch (err) {
      // console.log('err: ', err);
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="Email Address"
          name="id"
          onChange={handleChange}
          required
          value={values.id}
        />

        <TextField
          fullWidth
          type="password"
          label="Password"
          name="password"
          onChange={handleChange}
          required
          value={values.password}
        />

        <Button
          disabled={loading}
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          type="submit"
          variant="contained"
        >
          Login
        </Button>
      </Stack>
    </form>
  );
};

const LoginWithPhoneNumber = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { error: toastError, success } = useToast();
  const [phoneNumber,setPhoneNumber] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [initialize, signInWithPhone, verifyCode, reset, signInStatus, error, loggedInUser] = useSignInWithPhoneNumber(auth, 'sign-in-with-phone');
  const [getUserRole, { data: userRole, error: userRoleError, },] = useLazyQuery(FETCH_USER_ROLE);

  const updatePhoneNumber = useCallback((event: any) => {
    setPhoneNumber(event.target.value);
  }, []);

  const updateOtpCode = useCallback((event: any) => {
    setOtpCode(event.target.value);
  }, []);

  const handleSubmit = useCallback((event: any) => {
    event.preventDefault();
    if (signInStatus === PhoneSignInStatus.initial) {
      signInWithPhone(phoneNumber);
      return;
    }
    if (signInStatus === PhoneSignInStatus.codeSent) {
      verifyCode(otpCode);
      return;
    }
  }, [signInStatus, phoneNumber, signInWithPhone, otpCode, verifyCode]);

  useEffect(() => {
    if (loggedInUser) {
      loggedInUser.getIdToken().then((token) => {
        saveTokens(token);
        getUserRole();

      });
    }
  }, [getUserRole, loggedInUser]);

  useEffect(() => {
    if (userRoleError) {
      toastError("Login failed. Please try again");
      reset();
      return;
    }

    if (userRole && userRole.userRole === 'ADMIN') {
      success("Login Successful!");
        navigate("/users");
        return;
    } else if (userRole) {
      toastError("Login failed. Please try again");
      reset();
    }
  }, [toastError, userRoleError, navigate, success, userRole, reset]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (error === PhoneSignInError.smsSendingFailure) {
      toastError('Error while sending SMS.');
    }
    if (error === PhoneSignInError.otpVerificationFailure) {
      toastError('Error while verifying code.');
    }
  }, [error, toastError]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {signInStatus === PhoneSignInStatus.initial &&
          <>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              onChange={updatePhoneNumber}
              required
              value={phoneNumber}
            />
            <Button
            id="sign-in-with-phone"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Login
          </Button>
          </>
        }
        {signInStatus === PhoneSignInStatus.codeSent &&
          <>
          <TextField
            fullWidth
            label="Code"
            name="code"
            onChange={updateOtpCode}
            required
            value={otpCode}
            />
            <Button
            id="send-otp-code"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
            </>
        }

      </Stack>
    </form>
  );
};