import { useMutation, useQuery } from "@apollo/client";
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, colors, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from 'zod';
import { useToast } from "../../common/hooks/useToast";
import { UPDATE_USER } from "./users.mutation";
import { USER_DETAILS } from "./users.queries";


interface PropsInterface {
  user: any;
  handleClose: (isSuccess?: number) => void;
}

const schema = z.object({
  username: z.string().min(1, { message: 'Required' }),
  firstName: z.string().min(1, { message: 'Required' }),
  lastName: z.string().min(1, { message: 'Required' }),
  email: z.string().min(1, { message: 'Required' }),
  phone: z.string().nullable(),

});

type SchemaProps = z.infer<typeof schema>;

export const EditUser: React.FC<PropsInterface> = (props: PropsInterface) => {


  const {success, error: toastError} = useToast();

  const { data, loading } = useQuery(USER_DETAILS, { fetchPolicy: 'network-only', variables: { id: Number(props.user.id) } });

  const [ updateUser, updateResult ] = useMutation(UPDATE_USER);

  const [values, setValues] = useState<any>({});

  useEffect(() => {
    if (data) {
      const { username, firstName, lastName, email, phone } = data.fetchUserDetails;
      setValues({
        username,
        firstName,
        lastName,
        email,
        phone,
      });
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemaProps>({
    values,
    resolver: zodResolver(schema),
  });


  const submitData = useCallback((values: any) => {
    updateUser({ variables: { id: Number(props.user.id), userUpdateInput: {
      username: { "set": values.username },
      firstName: { "set": values.firstName },
      lastName: { "set": values.lastName },
      email: { "set": values.email },
      phone: { "set": values.phone },
    } }})
  }, [props.user.id, updateUser]);

  useEffect(() => {
    if (updateResult.error) {
        toastError('Updating the user failed. Please try again.');
    } else if (updateResult.data) {
        success('User updated successfully');
        props.handleClose(1);
    }
  }, [updateResult.error, updateResult.data, toastError, success, props]);

  if (loading) {
    return (<></>);
  }

  return (
    <Container maxWidth='lg' sx={{ width: '45rem', backgroundColor: colors.common.white, p: '2rem', border: '1px solid black' }}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 3 }} variant="h4">Edit user</Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit((d) => submitData(d))}
        >
          <Stack spacing={2}>
            <TextField label="Username" {...register('username')} error={!!errors.username} helperText={errors.username?.message}  />
            <TextField label="First name" {...register('firstName')} error={!!errors.firstName} helperText={errors.firstName?.message}/>
            <TextField label="Last name" {...register('lastName')} error={!!errors.lastName} helperText={errors.lastName?.message}/>
            <TextField label="Email" {...register('email')} error={!!errors.email} helperText={errors.email?.message}/>
            <TextField label="Phone" {...register('phone')} error={!!errors.phone} helperText={errors.phone?.message}/>
          </Stack>

          <div style={{marginTop: '1em'}}>
            <Button size="large" sx={{ marginRight: 3 }} type="submit" variant="contained" color="success">
                Update
            </Button>

            <Button size="large" type="button" color="error" onClick={() => props.handleClose()}>
                Cancel
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
);
};