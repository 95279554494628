import { gql } from '@apollo/client';

export const USERS = gql`
  query fetchUsers(
    $where: UserWhereInput
    $pagination: PaginationInputType
    $orderBy: UserOrderByWithRelationInput
  ) {
    fetchUsers(where: $where, pagination: $pagination, orderBy: $orderBy) {
      users {
        id
        username
        firstName
        lastName
        email
        phone
        totalPoints
        isNotificationEnabled
        membershipType
        isEmailVerified
        isPhoneVerified
        City {
          name
        }
        Country {
          name
        }
        State {
          name
        }
        ReferredByReferral {
          RefferedByUser {
            firstName
            lastName
            username
          }
        }
        Referrals {
          id
        }
      }
      total
    }
  }
`;

export const USER_DETAILS = gql`
  query fetchUserDetails($id: Int!) {
    fetchUserDetails(id: $id) {
      username
      firstName
      lastName
      email
      phone
    }
  }
`;
